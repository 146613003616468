import { navigate } from "gatsby"
import React from "react"
import { useState } from "react"
import { Button, ButtonColour, ButtonSize } from "../../layout/Button"
import { Option, Select } from "../../layout/Select"

interface Props {}

export enum ContactCategory {
    GAME_DEV = "game-development",
    WEB_DEV = "web-development",
    NATIVE_DEV = "native-development",
    SAAS_DEV = "saas-development",
}

const contactData: ContactData = {
    title: "Have an idea?",
    subtitle: "Let us know",
    buttonLabel: "Get in touch",
    options: [
        {
            label: "Game Development",
            value: ContactCategory.GAME_DEV,
        },
        {
            label: "Web Development",
            value: ContactCategory.WEB_DEV,
        },
        {
            label: "Native Development",
            value: ContactCategory.NATIVE_DEV,
        },
        {
            label: "SaaS Development",
            value: ContactCategory.SAAS_DEV,
        },
    ],
}

type ContactData = {
    title: string
    subtitle: string
    buttonLabel: string
    options: Option[]
}

type ContactFormState = {
    "bot-field": any
    "user-name": string
    email: string
    "phone-number": string
    category: ContactCategory
    message: string
}

export const Contact: React.FC<Props> = ({}) => {
    const [formState, setFormState] = useState<ContactFormState>({
        "bot-field": "",
        "user-name": "",
        "phone-number": "",
        category: ContactCategory.GAME_DEV,
        email: "",
        message: "",
    })

    function encode(data: any) {
        return Object.keys(data)
            .map(
                (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(data[key])
            )
            .join("&")
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": "contact-form",
                ...formState,
            }),
        })
            .then(() => navigate("/contact/thanks/"))
            .catch((error) => alert(error))
    }
    return (
        <>
            <h2 className="contact-title">{contactData.title}</h2>
            <p>{contactData.subtitle}</p>

            <form
                name="contact-form"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                action="/contact/thanks/"
            >
                <input type="hidden" name="form-name" value="contact-form" />
                <input type="hidden" name="bot-field" />

                <div className="field">
                    <label htmlFor="user-name">Name</label>
                    <input
                        type="text"
                        name="user-name"
                        id="user-name"
                        placeholder="Name"
                        value={formState["user-name"]}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                "user-name": e.currentTarget.value,
                            })
                        }}
                        required
                    />
                    <span className="required" />
                </div>

                <div className="field">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={formState.email}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                email: e.currentTarget.value,
                            })
                        }}
                        placeholder="Email"
                        required
                    />
                    <span className="required" />
                </div>

                <div className="field">
                    <label htmlFor="phone-number">Phone Number</label>
                    <input
                        type="telephone"
                        name="phone-number"
                        id="phone-number"
                        value={formState["phone-number"]}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                "phone-number": e.currentTarget.value,
                            })
                        }}
                        placeholder="(555) 555 5555"
                    />
                </div>

                <div className="field">
                    <label htmlFor="category">Category</label>
                    <select
                        className="hidden-field"
                        name="category"
                        id="category"
                        value={formState.category}
                    >
                        {contactData.options.map((option, i) => {
                            return (
                                <option
                                    key={`hidden-select-${option.label}-${i}`}
                                    value={option.value}
                                >
                                    {option.label}
                                </option>
                            )
                        })}
                    </select>
                    <Select
                        options={contactData.options}
                        onChange={(value: string) =>
                            setFormState({
                                ...formState,
                                category: value as ContactCategory,
                            })
                        }
                    />
                </div>

                <div className="textarea-field">
                    <label htmlFor="message">Message</label>
                    <textarea
                        name="message"
                        id="message"
                        value={formState.message}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                message: e.currentTarget.value,
                            })
                        }}
                        placeholder="Tell us about your project..."
                        required
                    />
                    <span className="required" />
                </div>

                <Button
                    buttonConfig={{
                        id: "contactGradient",
                        isHTMLButton: true,
                        ctaLabel: contactData.buttonLabel,
                        ctaLink: "submit",
                        size: ButtonSize.LARGE,
                        colour: ButtonColour.BLUE,
                        eventFunctions: {
                            onClick: handleSubmit,
                        },
                    }}
                />
            </form>
        </>
    )
}
