import React from "react";
import { useState } from "react";
import { DownArrowIcon } from "./icons/DownArrowIcon";

interface Props {
    options: Option[];
    onChange: (value: string) => void;
}

export type Option = {
    label: string,
    value: string,
}

export const Select: React.FC<Props> = ({ options, onChange }) => {
    const [value, setValue] = useState<string>(options[0].label)
    const [open, setOpen] = useState<boolean>(false);

    const renderOptions = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < options.length; ++i) {
            const element = (
                <div
                    className="option"
                    key={`hidden-select-${options[i].label}-${i}`}
                    onClick={() => {
                        setValue(options[i].label);
                        onChange(options[i].value);
                        setOpen(false);
                    }}
                >
                    {options[i].label}
                </div>
            );

            elements.push(element);
        }

        return elements;
    }

    return (
        <div className={`select ${open ? "open" : ""}`}>
            <div className="select-value" onClick={() => setOpen(!open)}>
                {value}
                <DownArrowIcon fill={"#080a2e"} />
            </div>
            {open &&
                <div className="options">
                    {renderOptions()}
                </div>
            }
        </div>
    )
}