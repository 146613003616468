export const contactPageData: ContactPageData = {
    metaPageTitle: "Contact Us - iDream Interactive",
    metaKeywords: "idream interactive, contact idream, contact idream interactive, connect with idream, idream interactive contact details",
    metaDescription: "Do you have an idea and would like our help to make it a reality? We’d be happy to connect with you.",
    title: "Keep In Touch",
    formTitle: "Have an idea?",
    formSubtitle: "Let us know"
}

type ContactPageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    formTitle: string,
    formSubtitle: string,
}