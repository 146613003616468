import React from "react";

interface Props {
    fill: string;
}

export const DownArrowIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={fill}>
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
    )
}