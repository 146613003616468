import React from "react"
import Layout from "../components/layout/Layout";
import { graphql } from "gatsby"
import { Navigation } from "../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../components/layout/ParallaxHeader";
import { HeaderInfo } from "../components/layout/HeaderInfo";
import { Container } from "../components/layout/Container";
import { contactPageData } from "../content/pageData/ContactPageData";
import { Contact } from "../components/sections/contact/Contact";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    return (
        <Layout
            pageTitle={contactPageData.metaPageTitle}
            keywords={contactPageData.metaKeywords}
            description={contactPageData.metaDescription}
            url={"contact"}
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.SMALL} straightBottom={true}>
                <HeaderInfo
                    title={contactPageData.title}
                    className="centered-header contact-header"
                />
            </ParallaxHeader>
            <Container className="contact">
                <Contact />
            </Container>
        </Layout>
    );
};



export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`